module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Empireo","short_name":"Empireo","start_url":"/","background_color":"#3498db","theme_color":"#3498db","display":"minimal-ui","icon":"src/images/icon.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"351292e4459e196f351b77730e3f8237"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5JJ4HL7","includeInDevelopment":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
