// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baza-produktow-js": () => import("./../../../src/pages/baza-produktow.js" /* webpackChunkName: "component---src-pages-baza-produktow-js" */),
  "component---src-pages-baza-wiedzy-o-kontrahentach-js": () => import("./../../../src/pages/baza-wiedzy-o-kontrahentach.js" /* webpackChunkName: "component---src-pages-baza-wiedzy-o-kontrahentach-js" */),
  "component---src-pages-cechy-systemu-crm-dla-agencji-reklamowej-js": () => import("./../../../src/pages/cechy-systemu-crm-dla-agencji-reklamowej.js" /* webpackChunkName: "component---src-pages-cechy-systemu-crm-dla-agencji-reklamowej-js" */),
  "component---src-pages-cennik-wynajem-js": () => import("./../../../src/pages/cennik-wynajem.js" /* webpackChunkName: "component---src-pages-cennik-wynajem-js" */),
  "component---src-pages-cennik-zakup-js": () => import("./../../../src/pages/cennik-zakup.js" /* webpackChunkName: "component---src-pages-cennik-zakup-js" */),
  "component---src-pages-crm-dla-agencji-marketingowej-js": () => import("./../../../src/pages/crm-dla-agencji-marketingowej.js" /* webpackChunkName: "component---src-pages-crm-dla-agencji-marketingowej-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dziekujemy-za-kontakt-js": () => import("./../../../src/pages/dziekujemy-za-kontakt.js" /* webpackChunkName: "component---src-pages-dziekujemy-za-kontakt-js" */),
  "component---src-pages-faktury-i-platnosci-js": () => import("./../../../src/pages/faktury-i-platnosci.js" /* webpackChunkName: "component---src-pages-faktury-i-platnosci-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-rodo-crm-js": () => import("./../../../src/pages/faq-rodo-crm.js" /* webpackChunkName: "component---src-pages-faq-rodo-crm-js" */),
  "component---src-pages-faq-rodo-js": () => import("./../../../src/pages/faq-rodo.js" /* webpackChunkName: "component---src-pages-faq-rodo-js" */),
  "component---src-pages-funkcje-js": () => import("./../../../src/pages/funkcje.js" /* webpackChunkName: "component---src-pages-funkcje-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integracja-z-poczta-js": () => import("./../../../src/pages/integracja-z-poczta.js" /* webpackChunkName: "component---src-pages-integracja-z-poczta-js" */),
  "component---src-pages-jaki-crm-dla-agencji-reklamowej-js": () => import("./../../../src/pages/jaki-crm-dla-agencji-reklamowej.js" /* webpackChunkName: "component---src-pages-jaki-crm-dla-agencji-reklamowej-js" */),
  "component---src-pages-komunikator-js": () => import("./../../../src/pages/komunikator.js" /* webpackChunkName: "component---src-pages-komunikator-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-koordynacja-pracy-js": () => import("./../../../src/pages/koordynacja-pracy.js" /* webpackChunkName: "component---src-pages-koordynacja-pracy-js" */),
  "component---src-pages-latwe-zarzadzanie-katalogiem-produktow-i-cennikami-js": () => import("./../../../src/pages/latwe-zarzadzanie-katalogiem-produktow-i-cennikami.js" /* webpackChunkName: "component---src-pages-latwe-zarzadzanie-katalogiem-produktow-i-cennikami-js" */),
  "component---src-pages-metody-finansowania-js": () => import("./../../../src/pages/metody-finansowania.js" /* webpackChunkName: "component---src-pages-metody-finansowania-js" */),
  "component---src-pages-narzedzia-do-pracy-zdalnej-js": () => import("./../../../src/pages/narzedzia-do-pracy-zdalnej.js" /* webpackChunkName: "component---src-pages-narzedzia-do-pracy-zdalnej-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-ofertowanie-i-zamowienia-js": () => import("./../../../src/pages/ofertowanie-i-zamowienia.js" /* webpackChunkName: "component---src-pages-ofertowanie-i-zamowienia-js" */),
  "component---src-pages-panel-klienta-js": () => import("./../../../src/pages/panel-klienta.js" /* webpackChunkName: "component---src-pages-panel-klienta-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-program-partnerski-js": () => import("./../../../src/pages/program-partnerski.js" /* webpackChunkName: "component---src-pages-program-partnerski-js" */),
  "component---src-pages-promocja-js": () => import("./../../../src/pages/promocja.js" /* webpackChunkName: "component---src-pages-promocja-js" */),
  "component---src-pages-raporty-js": () => import("./../../../src/pages/raporty.js" /* webpackChunkName: "component---src-pages-raporty-js" */),
  "component---src-pages-ups-js": () => import("./../../../src/pages/ups.js" /* webpackChunkName: "component---src-pages-ups-js" */),
  "component---src-pages-zarzadzanie-projektami-js": () => import("./../../../src/pages/zarzadzanie-projektami.js" /* webpackChunkName: "component---src-pages-zarzadzanie-projektami-js" */),
  "component---src-pages-zarzadzanie-szansami-sprzedazy-js": () => import("./../../../src/pages/zarzadzanie-szansami-sprzedazy.js" /* webpackChunkName: "component---src-pages-zarzadzanie-szansami-sprzedazy-js" */)
}

